import React, { useState } from 'react';
import { getStaticImageByIdentifier } from '../../services/image.service';
import './services-modal.scss';
const filesBaseUrl = process.env.filesBaseUrl;

export default function ServicesModal(props) {
    const showHideModal = props.show ? "modal-container display-block" : "modal-container display-none"
  return (
    <div className={showHideModal}>
      <section id="service-modal" className="service-modal">
        <div id="modal-wrapper" className="modal-wrapper">
          <button className="modal-close-button" onClick={ props.handleClose }></button>
          <div className="modal-row">
            <div className="modal-photo" style={{ backgroundImage: `url('${getStaticImageByIdentifier('services.modal.bg', props.staticImages)}')`}}>
              <div className="modal-photo-content padding-4 text-center">
                <img className='modal-photo-content-image' src={filesBaseUrl + props.photo} />
                <h3 className="text-uppercase">{props.name}</h3>
                <p>{props.description}</p>
                <h5 className="cta text-uppercase">{props.cta}</h5>
                <a href="/contact">
                  <button>Get Started</button>
                </a>
              </div>
            </div>
            <div className="modal-info">
              <div className="modal-bio-section">
                {
                  props.subFeatures?.map((x) => 
                  <div className='subfeature padding-b-3'>
                      <img className='sub-image' src={filesBaseUrl + x.icon.publicUrl} />
                      <div className='margin-l-3'>
                        <div className="modal-bio-name text-left"><h3 className="modal-bio-name-h3 text-uppercase">{x.title}</h3></div>
                        <div className="modal-bio-role text-left">{x.description}</div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}