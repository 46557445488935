import "./services.scss";

import React, { useState } from "react";
import ServicesRow from "./services-row";

import ServicesModal from "./services-modal";

export default (props) => {
    const [shownService, setShownService] = useState(null);
    return (
        <>
            <section>
                {props.serviceArray.map((x, i) => (
                    <ServicesRow
                        service={x}
                        setShownService={setShownService}
                        index={i}
                    />
                ))}
            </section>
            <ServicesModal
                show={shownService}
                handleClose={() => setShownService(null)}
                name={shownService?.title}
                description={shownService?.description}
                photo={shownService?.svgIcon?.publicUrl}
                cta={shownService?.cta}
                subFeatures={shownService?.features}
                staticImages={props.staticImages}
            />
        </>
    );
};
