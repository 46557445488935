import "./hero.scss"

import React from "react"
import servicesHero from '../../../static/images/services-hero.svg'
import { getStaticContentByIdentifier } from "../../services/misc.service";
import { getStaticImageByIdentifier } from "../../services/image.service";

export default (props) => {
    return (
        <section className="bg-gradient services-bg">
            <div className="services-hero">
                <div className="col-xs-12 col-md-9 text-center">
                    <h1 className = "mt-2">{getStaticContentByIdentifier('services.hero.title', props.staticContent)}</h1>
                    <p>{getStaticContentByIdentifier('services.hero.content', props.staticContent)}</p>
                </div>
                <img
                    src={getStaticImageByIdentifier('services.hero.image', props.staticImages)}
                    alt="Illustration of several people working on various projects"
                    className="fill service_picture"
                />
            </div>
        </section>
    )
}
