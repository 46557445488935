import "./howWeWork.scss"

import React from "react"
import honeyComb from "../../../static/images/services/process-honeycomb_dark.svg"
import iconBusiness from "../../../static/images/data-detail/business-icon.svg"
import iconUiUx from "../../../static/images/prod-eng-detail/ui-ux-icon.svg"
import { getStaticContentByIdentifier } from "../../services/misc.service";
import { getStaticImageByIdentifier } from "../../services/image.service";

/*const engagementPoints = [
  {
      title: 'Fixed Price',
      body: 'For smaller projects with solid requirements and detailed specifications, we take-on all project risks.'
  },{
      title: 'Time and Materials',
      body: 'For complex projects, we start with requirements gathering and then move through iterations of scoping and delivering.'
  },{
      title: 'Dedicated Team',
      body: 'If you simply need bandwidth on a project, our team can supplement your team and take ownership of the results.'
  }
];

const teams = [
  {
      title: 'On-site',
      body: 'We come to you and sit side-by-side with your team.'
  },{
      title: 'Remote',
      body: 'We take the project back to our shop and bring you the deliverable when complete.'
  },{
      title: 'Hybrid',
      body: 'We spend quality in-person time with your team and then iterate via email and video calls.'
  }
];

const honeyCombCards1 = [
  {
    header: "Innovation",
    text: "We are always learning, striving to be experts in what we do."
  },
  {
    header: "Discovery",
    text: "We take a holistic approach to get to the root of what you need."
  },
  {
    header: "Customization",
    text: "We know that every client is unique - we tailor our process to ensure your success."
  },
  {
    header: "Collaboration",
    text: "We work with you as a partner to ensure the success of not only the project, but of our relationship."
  }
];

const honeyCombCards2 = [
  {
    header: "Delivery",
    text: "We believe that delivery matters - we deliver what we commit to."
  },
  {
    header: "Improvement",
    text: "We strive to continually improve our development and internal processes."
  },
  {
    header: "Passion",
    text: "We are driven by customer satisfaction."
  }
]; */

export default (props) => {
  const honeyCombStyle = {
    backgroundImage: 'url(' + honeyComb + ')'
  }
  const isDataAvailable = (array) => {
    return array.length >= 1;
  }
    return (
      <section className="bg-gradient padding-b-6">
        <div className="how-we-work-container-flex">
          <div className="how-we-work-row row padding-t-6">
            <div className="col-xs-12 text-center">
              <h1>{getStaticContentByIdentifier('services.howwework.title', props.staticContent)}</h1>
            </div>
          </div>
          <div className="how-we-work-row row">
            <div className="col-xs-12 text-center padding-0">
              <img src={getStaticImageByIdentifier('services.image1', props.staticImages)} alt="Ruler, pen, and pencil icon" />
            </div>
          </div>
          <div className="how-we-work-row row">
            <div className="col-xs-12 text-center padding-t-3 padding-b-0">
              <h2>{getStaticContentByIdentifier('services.howwework.subtitle', props.staticContent)}</h2>
            </div>
            <div className="process-info-text col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-8 col-md-offset-2 text-center padding-0">
              <p>{getStaticContentByIdentifier('services.howwework.content', props.staticContent)}</p>
            </div>
          </div>
        </div>
        <div className="honeycomb-row">
          { isDataAvailable(props.row1Processes) 
              ? (
                props.row1Processes.map((x, i) => (
                  <div key={i} className="honeycomb" style={honeyCombStyle}>
                    <div className="inner-comb">
                      <div className="honey-title text-center">{x.title}</div>
                      <div className="honey-info text-center">{x.body}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div></div>
              )
            }
        </div>
        <div className="honeycomb-row-two">
          { isDataAvailable(props.row2Processes) 
              ? (
                props.row2Processes.map((x, i) => (
                  <div key={i} className="honeycomb-two" style={honeyCombStyle}>
                    <div className="inner-comb">
                      <div className="honey-title text-center">{x.title}</div>
                      <div className="honey-info text-center">{x.body}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div></div>
              )
            }
        </div>
        <div className="how-we-work-container-flex">
          <div className="how-we-work-row row">
            <div className="col-xs-12 text-center padding-0 padding-t-5-xs padding-t-0-md">
              <img src={getStaticImageByIdentifier('services.image2', props.staticImages)} alt="Illustration of three people connected by thought bubble with a light bulb inside" />
            </div>
          </div>
          <div className="how-we-work-row row">
            <div className="col-xs-12 text-center padding-t-3 padding-b-0 ">
              <h2>{getStaticContentByIdentifier('services.engagement.title', props.staticContent)}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="team-engagement-row">
              {props.engagementPoints.map((engagementPoint, index, array) => {
                return (
                  <div className="col-xs-12 col-sm-4 col-md-4 text-center" key={index}>
                    <h3 className="text-uppercase">{engagementPoint.title}</h3>
                    <p className="text-medium">{engagementPoint.body}</p>
                  </div>
                )
              })}
          </div>
        </div>
      </section>
    )
}
