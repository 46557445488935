import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import Cta from "../components/cta"
import ServicesHero from "../components/services/hero"
import Services from "../components/services/services"
import HowWeWork from "../components/services/howWeWork"
import Wave from "../components/wave"
import Seo from "../components/seo2"
import { getStaticContentByIdentifier } from "../services/misc.service";
import { getSEOByIdentifier } from "../services/seo.service";

const ServicesPage = props => {
    const staticContent = props.pageContext.staticContent;
    const staticImages = props.pageContext.staticImages;
    const serviceArray = props.pageContext.services;
    const updatedServiceArray = props.pageContext.updatedServices;
    const engagementPoints = props.pageContext.engagementPoints;
    const row1Processes = props.pageContext.row1Processes;
    const row2Processes = props.pageContext.row2Processes;
    const serviceTeams = props.pageContext.serviceTeams;
    const SEOInfo = props.pageContext.SEOInfo;
    

    return (
        <Layout>
          <Seo SEOInfo={getSEOByIdentifier('services', SEOInfo)}/>
          <Header/>
          <main id="maincontent">
            <ServicesHero staticContent={staticContent} staticImages={staticImages}/>
            <Wave color="#ffffff" background="#f8f8f8"/>
            <Services serviceArray={updatedServiceArray} staticContent={staticContent} staticImages={staticImages}/>
            <HowWeWork staticImages={staticImages} staticContent={staticContent} engagementPoints={engagementPoints} serviceTeams={serviceTeams} row1Processes={row1Processes} row2Processes={row2Processes}/>
            <Cta title={getStaticContentByIdentifier('services.CTA.content', staticContent)} button={getStaticContentByIdentifier('services.CTA.button', staticContent)}/>
          </main>
          <Footer/>
        </Layout>
    )
}

export default ServicesPage;