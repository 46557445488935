import React, { useState } from "react";
import ServicesModal from './services-modal';
import "./services-row.scss";
const filesBaseUrl = process.env.filesBaseUrl;

export default function ServicesRow({ service, setShownService, index }) {
    return (
        <div className='offset' id={service.anchor}>
            <div
                className={`${
                    index % 2 === 0
                    ? "service-row-container"
                    : "service-row-container-reverse"
                } padding-t-6 padding-b-6`}
                >
                <div className={`${index % 2 === 0 ? 'service-row' : 'service-row-reverse'} padding-l-3 padding-r-3`}>
                    <img
                        className="service-row-img"
                        src={filesBaseUrl + service.image.publicUrl}
                        anchor={service.anchor}
                        />
                    <div className={`${index % 2 === 0 ? 'service-row-text' : 'service-row-text-reverse'}`}>
                        <h2 className='title'>{service.title}</h2>
                        <p className='description'>{service.description}</p>
                        <button className="btn-secondary" onClick={(e) => setShownService(service)}>Learn More</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
